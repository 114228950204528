<template>
  <div class="answers-overview">
    <nav class="level">
      <div class="level-left">
        <p class="level-item">
          <b-button
            @click="reload"
            type="is-info"
            icon-left="reload">
            Reload
          </b-button>
        </p>
      </div>
    </nav>

    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <b-field label="Filter by form">
            <b-autocomplete
                v-model="filterForm"
                placeholder="Form name"
                :keep-first="false"
                :open-on-focus="false"
                :data="formFilterObj"
                field="name"
                @select="onFormChange">
            </b-autocomplete>
          </b-field>
        </div>
      </div>
    </nav>

    <b-table
      narrowed
      hoverable
      striped
      paginated
      backend-pagination
      @page-change="pageChange"
      :current-page.sync="page"
      default-sort="created"
      default-sort-direction="desc"
      :per-page="limit"
      :total="total"
      :loading="loading"
      :data="data">

      <template slot-scope="props">
        <b-table-column field="id" label="No." sortable>
          {{ props.index + 1 + parseInt(lastRequest.skip ? lastRequest.skip : 0) }}
        </b-table-column>

        <b-table-column field="correct_percentage" label="Correctness" sortable>
          {{ props.row.correct_percentage }}%
        </b-table-column>

        <b-table-column field="alias" label="Alias">
          {{ props.row.alias }}
        </b-table-column>

        <b-table-column field="status" label="Status" sortable>
          <span :class="statusClass(props.row.status)">{{ statusName(props.row.status) }}</span>
        </b-table-column>

        <b-table-column field="updated" label="Updated" sortable>
          <span class="tag is-info">
            {{ props.row.updated }}
          </span>
        </b-table-column>

        <b-table-column field="created" label="Created" sortable>
          <span class="tag is-warning">
            {{ props.row.created }}
          </span>
        </b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon
                icon="emoticon-sad"
                size="is-large">
              </b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: 'AccountsOverview',
  data: function() {
    return {
      loading: false,
      connection_error: false,

      filterForm: "",
      filterFormId: "",
      forms: [],
      filterAnswers: [],
      answers: [],

      lastRequest: {},
      total: 0,
      limit: 1,
      page: 1
    }
  },
  created: function() {
    this.formListFetch();
    this.fetch({
      limit: this.limit,
    })
  },
  computed: {
    formFilterObj() {
      return this.forms.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.filterForm.toLowerCase()) >= 0
      })
    },
    formFilter: function() {
      return this.formFilter;
    },
    data: function() {
      if (this.answers.length) {
        const moment = this.$moment

        return this.answers.map(answer => {
          const modified = {
            id: answer._id,
            updated: moment(answer.updatedAt).format('D / MM / YY - h:m A'),
            created: moment(answer.createdAt).format('D / MM / YY - h:m A'),
          }

          return {
            ...answer,
            ...modified
          }
        })
      }
      else {
        return []
      }
    },
  },
  methods: {
    reload: function() {
      this.fetch(this.lastRequest)
    },
    formListFetch: function() {
      const thisCompo = this

      this.$api.formsList({}).then((response) => {
        thisCompo.forms = response.data.list
      });
    },
    fetch: function(requestOptions={}) {
      const thisCompo = this

      this.loading = true
      this.connection_error = false
      this.lastRequest = requestOptions

      this.$api.answersList(requestOptions).then((response) => {
        thisCompo.answers = response.data.list

        thisCompo.limit = response.data.limit
        thisCompo.total = response.data.total

        thisCompo.loading = false
      }).catch( (err) => {
        if (err.response) {
          thisCompo.$buefy.toast.open({
            message: 'Bad request',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }
        else {
          thisCompo.$buefy.toast.open({
            message: 'Connection error',
            type: 'is-danger',
            position: 'is-bottom'
          })
        }

        thisCompo.loading = false
        thisCompo.connection_error = true
      })
    },
    pageChange: function(pageNum) {
      let option = {
        limit: this.limit,
        skip: this.limit * ( pageNum - 1 ),
      }
      if (this.filterFormId) {
        option.form = this.filterFormId;
      }

      this.fetch(option);

    },
    onFormChange: function(value) {
      let option = {
        limit: this.limit
      };

      if (value) {
        this.filterFormId = value._id;
        this.page = 1;

        option.form = value._id;
      } else {
        this.filterForm = "";
      }

      this.fetch(option);
    },

    statusName: function(id) {
      switch(id) {
        case true:
          return 'Enabled'

        case false:
          return 'Disabled'
      }
    },
    statusClass: function(id) {
      switch(id) {
        case true:
          return 'has-text-info'

        case false:
          return 'has-text-danger'
      }
    }
  },
}
</script>
