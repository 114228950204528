<template>
  <div class="page-asnwers">
    <div class="columns is-gapless is-vh">
      <div class="column is-2 has-background-dark">
        <Sidebar/>
      </div>

      <div class="column">
        <div class="section">
          <h1 class="title">Answers Overview</h1>

          <hr>

          <AnswersOverview/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import AnswersOverview from '@/components/AnswersOverview.vue'

export default {
  name: 'Answers',
  components: {
    Sidebar,
    AnswersOverview,
  }
}
</script>
